// Necessary if using App Router to ensure this file runs on the client
'use client';

import { getConfig } from '@/config';
import { datadogRum } from '@datadog/browser-rum';

const config = getConfig();

if (
  config.datadogRumConfig?.applicationId &&
  config.datadogRumConfig?.clientToken
) {
  datadogRum.init({
    ...config.datadogRumConfig,
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
