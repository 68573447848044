import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/opt/buildhome/repo/node_modules/.pnpm/antd@5.20.2_date-fns@3.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.1_@ant-design+cssinjs@1.21.1_react-dom@18.3.1_react@18.3.1__r_vlyibxoehtmn6mpd5srvofwgge/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/.pnpm/next-intl@3.17.4_next@14.2.3_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/app/antd.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/provider/datadog-init.tsx");
